<template>
  <div class="carbrands">

    <div class="container block-margin">
      <div class="mid-line text-center">
        <div class="mid-line__hr visible-lg"></div>
        <h3>
                  <span class="mid-line__around"
                  >Мы обслуживаем все популярные марки автомобилей в
                    России</span
                  >
        </h3>
      </div>
      <div class="my__carousel">
        <div class="my__carousel__stage">
          <transition-group name="my__carousel__list" >
            <div v-for="item in items" :key="item.title" class="carbrands__item__width">
              <div class="carbrands__item">
                  <div class="carbrands__around_pic">
                    <div class="carbrands__pic">
                      <div class="carbrands__inpic">
                        <img
                            class="carbrands__img"
                            :src="item.src"
                            :alt="item.title"
                            :title="item.title"
                        />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, onUpdated, onUnmounted, reactive} from 'vue'

export default {
  name: "CarBrands",
  components: {},
  setup() {
    const items = reactive([
      {title: "BMW", src: "/img/iblock/987/98765b5ffba767160c54d5073189d9f8.png"},
      {title: "Volvo", src: "/img/iblock/9ce/9ce81b2d00044737597bbacc31e9c976.png"},
      {title: "Volkswagen", src: "/img/iblock/804/80408a2cd0aef159bccccebf064db959.png"},
      {title: "UAZ", src: "/img/iblock/8c3/8c3ccba2145c9ab0b9e72370ef0e594c.png"},
      {title: "Toyota", src: "/img/iblock/981/9818155d17f23b16dab62463efe3cd14.png"},
      {title: "Skoda", src: "/img/iblock/ab5/ab5db5cb51c877a0c9589bcbd0d7071f.png"},
      {title: "Renault", src: "/img/iblock/cfd/cfd8cf1b92e6aa68a2d363f240b73835.png"},
      {title: "Porsche", src: "/img/iblock/5a7/5a73849a0674dd03d1f181df0f92f3ec.png"},
      {title: "Peugeot", src: "/img/iblock/5ca/5ca2b2f9d6a86efd3478b8b7e9301c76.png"},
      {title: "Opel", src: "/img/iblock/cde/cde03be2ac6ac153f38e490fb9e3720e.png"},
      {title: "Nissan", src: "/img/iblock/9da/9da61bf9195f9675978650989eb96084.png"},
      {title: "Mitsubishi", src: "/img/iblock/25b/25b28e337042c4903cd1b69701b7b16e.png"},
      {title: "Mercedes Benz", src: "/img/iblock/8e7/8e7a3283058e6592431fdce60dbf1c9c.png"},
      {title: "Mazda", src: "/img/iblock/c31/c31b943012d912e6e1391a871803dc24.png"},
      {title: "Lexus", src: "/img/iblock/eff/eff98ee8c1510c6a7720cf162baabfc2.png"},
      {title: "Land Rover", src: "/img/iblock/871/8710867ac96e70a7c753efd872a10253.png"},
      {title: "LADA", src: "/img/iblock/f04/f0467fe267ce91d6d4441218cf73bb96.png"},
      {title: "KIA", src: "/img/iblock/62f/62f40ddbff4e7b6ccc29d5219636edcd.png"},
      {title: "Jaguar", src: "/img/iblock/65c/65c278ea277de32856253d47720a4d69.png"},
      {title: "Jeep", src: "/img/iblock/e31/e3171083ba758d420343734427954674.png"}
    ])
    const options = {
      autoplay: {play: true, repeat: true, speed: 3000},
    }
    const refreshCarousel = () => {
      items.push(items.splice(0,1)[0])
    //  items.splice(0,1)
      setTimeout(refreshCarousel, options.autoplay.speed)
    }
    onMounted(() => {
      console.log('mounted!')
      setTimeout(refreshCarousel, options.autoplay.speed)
    })
    onUpdated(() => {
      console.log('updated!')
    })
    onUnmounted(() => {
      console.log('mounted!')
    })



    return {
      items,
      options,
      refreshCarousel
    }
  }
}
</script>

<style scoped>
.carbrands__item__width {
  width: 88px;
  padding-right: 10px;
  /* display: block;*/
  position: relative;
  float: left;
  transition: all 2s ease 0s;
}
.my__carousel__list{

}
.my__carousel {
  display: block;
  width: 100%;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.my__carousel__stage {
 /* transform: translate3d(-98px, 0px, 0px);*/

  width: 4290px;
}
.my__carousel__list-move{

}

.my__carousel__list-enter-active,
.my__carousel__list-leave-active {
 /* transition: all 2s ease 0s;*/
}
.carbrands__item__width-move,
.my__carousel__list-enter-from,
.my__carousel__list-leave-to {
  opacity: 0;
/*  transform: translate3d(9px, 0px, 0px);*/
}
</style>