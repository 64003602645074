
import {defineComponent} from 'vue';
import MyHeader from '@/components/MyHeader.vue'
import MyNav from '@/components/MyNav.vue'
import MyFooter from '@/components/MyFooter.vue'
export default defineComponent({
  name: 'App',
  components: {
    MyHeader,
    MyNav,
    MyFooter,
  }

});
