<template>
  <div class="b-mmenu navbar-default">
    <button
        type="button"
        class="b-mmenu__toggle navbar-toggle"
        data-toggle="collapse"
        data-target=".navbar-responsive-collapse"
    >
      <span class="full-width-menu">Меню</span>
      <span class="icon-toggle">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </span>
    </button>
    <div class="container">
      <div class="collapse navbar-collapse navbar-responsive-collapse">
        <ul
            class="nav navbar-nav list-unstyled main-menu-nav"
            style="overflow: hidden"
        >
          <li class="dropdown other invisible">
            <router-link to="index.html#">...</router-link>>
            <ul
                class="dropdown-menu list-unstyled dropdown-menu-right"
            ></ul>
          </li>
          <li class="dropdown lvl1" id="element0">

            <router-link to="/about"
                class="dropdown-toggle"
                data-toggle="dropdown"
            >Контакты<span class="hidden-md hidden-lg"><i></i></span
            ></router-link>>
          </li>

          <li class="dropdown lvl1" id="element3">
            <router-link to="/services"
                class="dropdown-toggle"
                data-toggle="dropdown"
            >Наши услуги</router-link>
          </li>
          <li class="dropdown lvl1" id="element4">
            <router-link to="/docs"
                class="dropdown-toggle"
                data-toggle="dropdown"
            >Документы<span class="hidden-md hidden-lg"><i></i></span
            ></router-link>>
            <ul class="dropdown-menu list-unstyled">
              <li class="dropdown-submenu">
                <router-link to="/docs"
                >Документы</router-link>

              </li>
              <li class="dropdown-submenu">
                <router-link to="/laws"
                >Законы и постановления</router-link>

              </li>
              <li class="dropdown-submenu">
                <router-link to="/docs"
                >Договор</router-link>

              </li>
            </ul>
          </li>
          <li class="dropdown lvl1" id="element5">
            <router-link to="/singup"
                class="dropdown-toggle"
                data-toggle="dropdown"
            >Записаться</router-link>

          </li>
        </ul>
      </div>
      <!-- Search Block -->
      <!--<ul
          class="nav navbar-nav navbar-border-bottom navbar-right list-unstyled search-bar"
      >
        <li>
          <i class="fas fa-search search-btn lupa search"></i>
          <div class="search-open">
            <form action="https://tehavtotest.ru/search/">
              <div class="input-group animated fadeInDown">
                <input
                    type="text"
                    name="q"
                    class="form-control"
                    placeholder="Поиск"
                /><span class="input-group-btn"
              ><button class="btn btn-primary" name="s" type="submit">
                        Искать
                      </button></span
              >
                <div class="search-close"></div>
              </div>
            </form>
          </div>
        </li>
      </ul> -->
      <!-- End Search Block -->
    </div>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
export default {
name: "MyNav"
}
</script>

<style scoped>

</style>