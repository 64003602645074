<template>
  <link href="/css/my.css" rel="stylesheet">

  <div id="svg-icons">
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <symbol viewBox="0 0 92 92" id="svg-location">
        <path d="M68.4,7.9C62.7,2.8,54.7,0,46,0S29.3,2.8,23.6,7.9C16.6,14.1,13,23.4,13,35c0,25.1,28.9,54.6,30.2,55.8
    c0.8,0.8,1.8,1.2,2.8,1.2s2.1-0.4,2.8-1.2C50.1,89.6,79,60.1,79,35C79,23.4,75.4,14.1,68.4,7.9z M46,82.1c-2.7-3-7-8-11.2-14
    C25.8,55.3,21,43.9,21,35c0-25,19.1-27,25-27c23.2,0,25,20.7,25,27C71,52.6,53.1,74.3,46,82.1z M46,17.3c-8.8,0-15.9,7.3-15.9,16.2
    S37.2,49.6,46,49.6c8.8,0,15.9-7.3,15.9-16.2S54.8,17.3,46,17.3z M46,42.6c-4.9,0-8.9-4.1-8.9-9.2s4-9.2,8.9-9.2
    c4.9,0,8.9,4.1,8.9,9.2S50.9,42.6,46,42.6z"></path>
      </symbol>
      <symbol viewBox="0 0 92 92" id="svg-message">
        <path
            id="XMLID_1284_"
            d="M88,6H4c-2.2,0-4,1.8-4,4v51.1C0,63.3,1.8,65,4,65h47v17c0,1.7,1.3,3.2,2.8,3.8c0.5,0.2,1,0.2,1.5,0.2	c1.2,0,2.3-0.5,3.1-1.4L75,65h13c2.2,0,4-1.7,4-3.9V10C92,7.8,90.2,6,88,6z M84,57H73.2c-1.2,0-2.5,0.6-3.3,1.4L59,71.1v-10	c0-2.2-1.4-4.1-3.6-4.1H8V14h76V57z M18.6,28c0-2.2,1.8-4,4-4h29c2.2,0,4,1.8,4,4s-1.8,4-4,4h-29C20.4,32,18.6,30.2,18.6,28z M62.5,31.1c-0.7-0.7-1.2-1.8-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.8-0.8,1.8-1.2,2.8-1.2c1.1,0,2.1,0.4,2.8,1.2 c0.7,0.7,1.2,1.8,1.2,2.8c0,1.1-0.4,2.1-1.2,2.8c-0.8,0.8-1.8,1.2-2.8,1.2C64.3,32.2,63.3,31.8,62.5,31.1z"></path>
      </symbol>
      <symbol id="svg-icon-flag" viewBox="0 0 92 92">
        <path
            id="XMLID_2053_"
            d="M86.8,0.1c-1.9-0.4-3.8,0.6-4.5,2.4C81.2,5,70.6,16,61.5,16.4c-5.2,0.2-10-3.4-15.1-7.2
	C39.8,4.4,32.5-1,23.1,0.8C9.2,3.4,2.7,15.9,2.4,16.5c-0.4,0.8-0.5,1.8-0.3,2.7l4,17.9c0,0,0,0,0,0l11.7,51.9
	c0.4,1.9,2.1,3.1,3.9,3.1c0.3,0,0.6,0,0.9-0.1c2.2-0.5,3.5-2.6,3-4.8l-6.8-30.3c1.8-2.8,6.9-9.7,14.9-11.5c4.8-1.1,8.6,0.2,13,1.6
	c4.9,1.6,10.5,3.5,17.7,1.9C79.5,45.4,89.7,27.4,90,4C90,2.1,88.7,0.5,86.8,0.1z M62.6,41.1c-5,1.1-8.9-0.2-13.4-1.7
	c-4.9-1.6-10.4-3.5-17.3-1.9c-6.8,1.5-11.9,5.5-15.4,9.3L16.1,45l-5.9-26c1.7-2.6,6.4-8.8,14.3-10.3c6-1.1,11.1,2.6,17,7
	c5.7,4.2,12.3,9,20.2,8.7c6.7-0.3,13.6-4.2,19-8.6C78.4,27.8,72.5,38.8,62.6,41.1z"
        />
      </symbol>
      <symbol id="svg-icon-headset" viewBox="0 0 92 92">
        <path
            d="M89,42.8l-0.1,25.3c0,2.2-1.8,3.9-4,3.9c0,0,0,0,0,0c-2.2,0-4-1.7-4-3.9L81,42.8c0-9.3-3.6-18-10.2-24.6
	C64.2,11.7,55.5,8,46.1,8c0,0-0.1,0-0.1,0c-19.2,0-34.9,15.5-35,34.6L11,68c0,2.2-1.8,4-4,4c0,0,0,0,0,0c-2.2,0-4-1.8-4-4l0.1-25.4
	C3.1,19.1,22.4,0,46,0c0,0,0.1,0,0.1,0c11.5,0,22.3,4.5,30.3,12.6C84.6,20.7,89,31.4,89,42.8z M36.6,44.9c0.7,0.7,1,1.4,1,2.3
	l-0.1,40.5c0,1.9-1.6,3.3-3.5,3.3c0,0,0,0,0,0c-13.2,0-17.5-7.6-18-11.8c0-0.1,0,0,0-0.2L16,56.2c0-0.1,0-0.2,0-0.4
	C16.5,51.7,20.9,44,34,44c0,0,0.1,0,0.1,0C35,44,35.9,44.2,36.6,44.9z M30.6,51.1c-6.3,1-7.4,4.7-7.6,5.4l-0.1,22.3
	c0.2,0.8,1.3,4.4,7.6,5.4L30.6,51.1z M76.1,56.2l0.1,22.7c0,0.1,0,0.2,0,0.3c-0.5,4.1-4.8,11.8-18,11.8c0,0,0,0,0,0
	c-1.9,0-3.5-1.3-3.5-3.3l-0.1-40.3c0-0.9,0.4-1.8,1-2.4c0.7-0.7,1.5-1,2.5-1c0,0,0.1,0,0.1,0c13.1,0,17.5,7.7,18,11.9
	C76.1,56,76.1,56,76.1,56.2z M69.2,78.8l-0.1-22.3c-0.2-0.8-1.3-4.4-7.6-5.4l0.1,33.1C68,83.1,69,79.5,69.2,78.8z"
        />
      </symbol>
      <symbol id="svg-icon-star" viewBox="0 0 92 92">
        <path
            id="XMLID_310_"
            d="M70.7,88c-0.8,0-1.5-0.2-2.2-0.6L46,72.7L23.5,87.4c-1.4,0.9-3.2,0.8-4.5-0.1c-1.3-1-1.9-2.7-1.5-4.3
	l7.1-25L3.4,40.1c-1.2-1-1.7-2.7-1.2-4.3s1.9-2.6,3.5-2.8L31,31.2L42.4,6.3C43,4.9,44.4,4,46,4s3,0.9,3.6,2.3L61,31.2L86.3,33
	c1.6,0.1,3,1.2,3.5,2.8c0.5,1.5,0,3.2-1.2,4.3L67.5,57.9l7.1,25c0.5,1.6-0.1,3.3-1.5,4.3C72.4,87.7,71.6,88,70.7,88z M46,64
	c0.8,0,1.5,0.2,2.2,0.6L64,74.9l-4.9-17.3c-0.4-1.5,0.1-3.1,1.3-4.1l15.5-13.1L58.1,39c-1.5-0.1-2.7-1-3.4-2.3L46,17.6l-8.7,19.1
	c-0.6,1.3-1.9,2.2-3.4,2.3l-17.8,1.3l15.5,13.1c1.2,1,1.7,2.6,1.3,4.1L28,74.9l15.8-10.2C44.5,64.2,45.2,64,46,64z"
        />
      </symbol>
      <symbol id="svg-icon-clock" viewBox="0 0 92 92">
        <path
            d="M46,0C20.6,0,0,20.6,0,46s20.6,46,46,46s46-20.6,46-46S71.4,0,46,0z M46,84C25,84,8,67,8,46S25,8,46,8s38,17,38,38
	S67,84,46,84z M61.3,55.6c1.6,1.6,1.6,4.1,0,5.7c-0.8,0.8-1.8,1.2-2.8,1.2s-2-0.4-2.8-1.2L43.2,48.8C42.4,48.1,42,47.1,42,46V22.2
	c0-2.2,1.8-4,4-4s4,1.8,4,4v22.2L61.3,55.6z"
        />
      </symbol>
      <symbol id="svg-icon-gear" viewBox="0 0 92 92">
        <path
            id="XMLID_160_"
            d="M46,65.3c-10.6,0-19.3-8.6-19.3-19.3c0-10.6,8.6-19.3,19.3-19.3c10.6,0,19.3,8.6,19.3,19.3
	C65.3,56.6,56.6,65.3,46,65.3z M46,33.8c-6.7,0-12.2,5.5-12.2,12.2c0,6.7,5.5,12.2,12.2,12.2c6.7,0,12.2-5.5,12.2-12.2
	C58.2,39.3,52.7,33.8,46,33.8z M46,92h-0.5c-3.5,0-6.5-0.4-6.7-0.4c-1.6-0.2-2.8-1.4-3.1-3l-1-7.2c-2.2-0.7-4.3-1.6-6.3-2.7L22.8,83
	c-1.3,1-3,0.9-4.3,0c-0.1-0.1-2.5-2-5-4.4l-0.4-0.4c-2.5-2.5-4.3-4.9-4.4-5c-1-1.3-1-3,0-4.3l4.4-5.8c-1.1-2-1.9-4.2-2.6-6.4l-7-1
	c-1.6-0.2-2.8-1.5-3-3.1C0.4,52.5,0,49.5,0,46v-0.5c0-3.5,0.4-6.5,0.4-6.7c0.2-1.6,1.4-2.8,3-3.1l7.2-1c0.7-2.2,1.6-4.3,2.7-6.3
	L9,22.8c-1-1.3-0.9-3,0-4.3c0.1-0.1,2-2.5,4.4-5l0.4-0.4c2.5-2.5,4.9-4.3,5-4.4c1.3-1,3-1,4.3,0l5.8,4.4c2-1.1,4.2-1.9,6.4-2.6l1-7
	c0.2-1.6,1.5-2.8,3.1-3C39.5,0.4,42.5,0,46,0h0.5c3.5,0,6.5,0.4,6.7,0.4c1.6,0.2,2.8,1.4,3.1,3l1,7.2c2.2,0.7,4.3,1.6,6.3,2.7
	L69.2,9c1.3-1,3-0.9,4.3,0c0.1,0.1,2.5,2,5,4.4l0.4,0.4c2.5,2.5,4.3,4.9,4.4,5c1,1.3,1,3,0,4.3L79,28.9c1.1,2,1.9,4.2,2.6,6.4l7,1
	c1.6,0.2,2.8,1.5,3,3.1c0,0.1,0.4,3.2,0.4,6.7v0.5c0,3.5-0.4,6.5-0.4,6.7c-0.2,1.6-1.4,2.8-3,3.1l-7.2,1c-0.7,2.2-1.6,4.3-2.7,6.3
	l4.3,5.7c1,1.3,0.9,3,0,4.3c-0.1,0.1-2,2.5-4.4,5l-0.4,0.4c-2.5,2.5-4.9,4.3-5,4.4c-1.3,1-3,1-4.3,0L63.1,79c-2,1.1-4.2,1.9-6.4,2.6
	l-1,7c-0.2,1.6-1.5,2.8-3.1,3C52.5,91.6,49.5,92,46,92z M42.4,84.8c0.9,0.1,2,0.1,3.1,0.1H46c1.1,0,2.2,0,3.1-0.1l0.9-6.6
	c0.2-1.5,1.3-2.6,2.7-3c3.1-0.7,6.1-1.9,8.8-3.6c0.8-0.5,1.8-0.6,2.7-0.4c0.5,0.1,1,0.4,1.4,0.7l5.3,4c0.7-0.6,1.5-1.3,2.3-2.1
	l0.4-0.4c0.8-0.8,1.5-1.6,2.1-2.3l-4-5.3c-0.9-1.2-0.9-2.8-0.2-4c1.7-2.6,2.9-5.5,3.6-8.5c0.3-1.5,1.5-2.7,3-2.9l6.7-0.9
	c0.1-0.9,0.1-2,0.1-3.1V46c0-1.1,0-2.2-0.1-3.1L78.2,42c-1.4-0.2-2.6-1.3-3-2.7c-0.7-3.1-1.9-6-3.5-8.7c-0.2-0.2-0.3-0.5-0.4-0.8
	c-0.4-1.1-0.2-2.4,0.5-3.3l4.1-5.4c-0.6-0.7-1.3-1.5-2.1-2.3l-0.4-0.4c-0.8-0.8-1.6-1.5-2.3-2.1l-5.3,4c-1.3,0.9-3,0.9-4.2,0
	c-2.6-1.6-5.5-2.8-8.5-3.6c-1.4-0.3-2.5-1.5-2.7-3l-0.9-6.7c-0.9-0.1-2-0.1-3.1-0.1H46c-1.1,0-2.2,0-3.1,0.1L42,13.8
	c-0.2,1.6-1.4,2.8-3,3c-3,0.7-5.9,1.9-8.5,3.5c-0.8,0.5-1.8,0.6-2.7,0.4c-0.5-0.1-1-0.4-1.4-0.7l-5.2-4c-0.7,0.6-1.5,1.3-2.3,2.1
	l-0.4,0.4c-0.8,0.8-1.5,1.6-2.1,2.3l4,5.3c0.9,1.2,0.9,2.7,0.2,4c-0.1,0.1-0.1,0.2-0.2,0.3c-1.6,2.6-2.8,5.5-3.5,8.5
	c-0.3,1.4-1.5,2.5-3,2.7l-6.7,0.9c-0.1,0.9-0.1,2-0.1,3.1V46c0,1.1,0,2.2,0.1,3.1l6.6,0.9c1.6,0.2,2.8,1.4,3,3c0,0,0,0.1,0,0.1
	c0.7,3,1.9,5.8,3.5,8.4c0.5,0.9,0.7,2,0.3,3c-0.1,0.4-0.4,0.8-0.7,1.2L16,70.9c0.6,0.7,1.3,1.5,2.1,2.3l0.4,0.4
	c0.8,0.8,1.6,1.5,2.3,2.1l5.3-4c1.3-1,3.2-0.9,4.4,0.1c2.6,1.6,5.4,2.7,8.3,3.5c1.4,0.3,2.5,1.5,2.7,3L42.4,84.8z"
        />
      </symbol>
    </svg>
  </div>
  <MyHeader></MyHeader>
  <MyNav></MyNav>
  <router-view/>
  <MyFooter></MyFooter>

</template>

<script lang="ts">
import {defineComponent} from 'vue';
import MyHeader from '@/components/MyHeader.vue'
import MyNav from '@/components/MyNav.vue'
import MyFooter from '@/components/MyFooter.vue'
export default defineComponent({
  name: 'App',
  components: {
    MyHeader,
    MyNav,
    MyFooter,
  }

});
</script>

<style>

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
